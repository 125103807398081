.selectList {
  height: 36px;
  overflow: hidden;
  position: relative;
  &:hover, &:active {
    overflow: visible;
  }
}
.selectList__button {
  border-radius: 20px;
  border: none;
  height: 36px;
  color: #A9A9A9;
  font-weight: normal;
  font-size: 15px;
  padding: 0 38px 0 20px;
  line-height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1L5.70711 4.29289C5.31658 4.68342 4.68342 4.68342 4.29289 4.29289L1 1' stroke='%23A9A9A9' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center right 20px;
}
.selectList:hover > .selectList__button, .selectList:active > .selectList__button {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  color: #262626;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5L5.70711 1.70711C5.31658 1.31658 4.68342 1.31658 4.29289 1.70711L1 5' stroke='%23262626' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.selectList__categoriesWrapper {
  position: absolute;
  width: 100%;
  top: 100%;
  min-width: 170px;
  z-index: 2;
  padding-top: 10px;
}
.selectList__categories {

  background: #FFFFFF;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}
.selectList__category {
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid #DDDDDD;
  text-decoration: none;
  color: #A9A9A9;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: #00792B;
    color: #fff;
  }
}