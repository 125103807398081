.news {
  border-radius: 15px;
  margin-bottom: 100px;
}
.news__title {
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  color: #262626;
}
.news__subtitle {
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 20px;
  color: #A9A9A9;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    color: #A9A9A9;
  }
  &::after {
    margin-left: 11.25px;
    content: '';
    display: block;
    height: 21.5px;
    width: 21.5px;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.328 6.46731C12.0338 6.17572 11.5589 6.17784 11.2673 6.47204C10.9757 6.76624 10.9778 7.24111 11.272 7.53269L12.6824 8.93054C13.2573 9.50033 13.6427 9.88386 13.9018 10.2065C13.9137 10.2213 13.9251 10.2358 13.9362 10.25L7 10.25C6.58579 10.25 6.25 10.5858 6.25 11C6.25 11.4142 6.58579 11.75 7 11.75L13.9362 11.75C13.9251 11.7642 13.9137 11.7787 13.9018 11.7935C13.6427 12.1161 13.2573 12.4997 12.6824 13.0695L11.272 14.4673C10.9778 14.7589 10.9757 15.2338 11.2673 15.528C11.5589 15.8222 12.0338 15.8243 12.328 15.5327L13.7691 14.1043C14.3053 13.573 14.7525 13.1298 15.0713 12.7328C15.4057 12.3163 15.6601 11.8787 15.7281 11.3455C15.7427 11.2308 15.75 11.1154 15.75 11C15.75 10.8846 15.7427 10.7692 15.7281 10.6545C15.6601 10.1213 15.4057 9.68367 15.0713 9.26724C14.7525 8.87023 14.3053 8.42705 13.7691 7.89568L12.328 6.46731Z' fill='%23A9A9A9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z' fill='%23A9A9A9'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.news__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 230px;
  grid-gap: 24px;
}
.news__item {
  background: #FFFFFF;
  border-radius: 15px;
  display: block;
  overflow: hidden;
}

.news__name {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #262626;
  text-decoration: none;
  margin-bottom: 5px;
}

.news__link {
  text-decoration: none;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.news__intro {
  font-size: 15px;
  line-height: 19px;
  color: #A9A9A9;
  max-height: 36px;
  overflow: hidden;
}
.news__img {
  width: 100%;
  max-height: 85px;
  object-fit: cover;
  border-radius: 15px;
  margin-top: auto;
  margin-bottom: 0;
  flex-grow: 1;
  flex-shrink: 0;
}
.news__date {
  font-size: 15px;
  line-height: 18px;
  color: rgba(169, 169, 169, 0.5);
  order: 4;
  margin-top: 10px;
  margin-bottom: 0;
}

.news__item:nth-child(1),
.news__item:nth-child(8) {
  grid-column: span 2;
  grid-row: span 1;
}

.news__item:nth-child(3),
.news__item:nth-child(7) {
  grid-column: span 1;
  grid-row: span 2;
}

.news__item:nth-child(1),
.news__item:nth-child(8) {
  .news__name,
  .news__intro,
  .news__date {
    max-width: calc(50% - 12px);
  }

  .news__img {
    position: absolute;
    width: 247px;
    height: calc(100% - 20px);
    right: 20px;
    object-fit: cover;
    max-height: inherit;
  }

  .news__date {
    margin-top: auto;
    margin-bottom: 0;
  }
}

.news__item:nth-child(4) {
  .news__img {
    max-height: 108px;
  }
}

.news__item:nth-child(3),
.news__item:nth-child(7) {
  .news__img {
    max-height: 210px;
  }

  .news__name {
    max-height: 92px;
  }

  .news__intro {
    max-height: 119px;
  }
}

@include media-breakpoint-down(xl) {
  .news__container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include media-breakpoint-down(lg) {
  .news__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-down(md) {
  .news__container {
    display: block;
  }
  .news__link {
    padding: 10px 11px;
  }
  .news__img {
    order: 1;
    margin-bottom: 10px;
    position: static;
    width: 100%;
  }
  .news__name {
    order: 2;
    margin-bottom: 0;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
  }
  .news__date {
    order: 3;
    margin-bottom: 6.32px;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    margin-top: 0;
  }
  .news__intro {
    order: 4;
    font-size: 12px;
    line-height: 19px;
    color: #262626;
    margin-bottom: 0;
  }
  .news__item {
    margin-bottom: 20px;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
    &:last-child {
      margin-bottom: 0;
    }
  }
  .news__item:nth-child(1),
  .news__item:nth-child(8),
  .news__item:nth-child(3),
  .news__item:nth-child(7) {
    .news__img {
      position: static;
      width: 100%;
    }

    .news__name {
      max-width: 100%;
    }

    .news__intro {
      max-width: 100%;
    }

    .news__date {
      margin-bottom: 6.32px;
    }
  }
}
