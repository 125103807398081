@import "node_modules/@splidejs/splide/src/css/core/index.scss";
.spaceMain {
  margin-bottom: 30px;

}
.spaceMain__article {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.spaceMain__content {
  width: calc(50% - 12px);
}

.spaceMain__content > p {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 26px;
  color: #262626;
  &:last-child {
    margin-bottom: 0;
  }
}

.spaceMain__image {
  width: calc(50% - 12px);
  height: 272px;
}

.spaceMain__image > img {
  border-radius: 10px;
  filter: drop-shadow(4px 4px 14px rgba(0, 0, 0, 0.15));
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spaceGallery__button {
  border-radius: 20px;
  border: 1px solid #00792B;
  height: 36px;
  font-size: 15px;
  color: #00792B;
  padding: 0 31px;
  line-height: 36px;
  text-decoration: none;
  &:hover, &:active {
    color: #fff;
    background-color: #00792B;
  }
}

.spaceGallery__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.spaceGallery__title {
  color: #262626;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 0;
}

.spaceGallery__gallery {
  width: 100%;
  margin-top: 24px;
  height: 191px;
}

.splide__slide img {
  width: 100%;
  max-width: 250px;
  max-height: 160px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}


.splide__arrow {
  position: absolute;
  z-index: 1;
  height: 60px;
  width: 60px;
  background-color: #FFFFFF;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 50%;
  top: 0;
  bottom:0;
  margin: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.2929 10.2929C10.6834 10.6834 10.6834 11.3166 10.2929 11.7071L1 21' stroke='%2300792B' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.splide__arrow--next {
  right: -30px;
}
.splide__arrow--prev {
  left: -30px;
  transform: rotate(180deg);
}

.spaceGalleryShow {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  align-items: center;
  justify-content: center;
  display: none;
  &_active {
    display: flex;
  }
}
.spaceGalleryShow__content {
  max-width: 80%;
  position: relative;
}
.spaceGalleryShow__image {
  display: none;
  &_active {
    display: block;
  }
}
.spaceGalleryShow__images img{
  max-width: 500px;
  max-height: 500px;
}

.spaceGalleryShow__arrow {
  font-size: 0;
  height: 60px;
  width: 60px;
  background-color: #FFFFFF;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L10.2929 10.2929C10.6834 10.6834 10.6834 11.3166 10.2929 11.7071L1 21' stroke='%2300792B' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  bottom: 30px;
  &_prev {
    left: 30px;
    transform: rotate(180deg);
  }
  &_next {
    right: 30px;
  }
}
.spaceGalleryShow__close {
  bottom: calc(100% + 22px);
  right: 0;
  font-size: 0;
  height: 23.5px;
  width: 23.5px;
  border: none;
  background-color: transparent;
  background-position: center;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L24.5 25' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M24.5 1.5L1 25' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  position: absolute;
}


@include media-breakpoint-down(md) {
  .spaceMain__content > p {
    font-size: 12px;
    line-height: 130%;
    margin-bottom: 20px;
  }
  .spaceMain__content {
    width: 100%;
    order: 2;
  }
  .spaceMain__image {
    width: 100%;
    order: 1;
    margin-bottom: 20px;
  }
  .splide__arrow {
    height: 40px;
    width: 40px;
    background-size: 5px 10px;
  }
  .splide__arrow--next {
    right: -20px;
  }
  .splide__arrow--prev {
    left: -20px;
  }
  .spaceGallery__gallery {
    flex-wrap: wrap;
  }
  .spaceGallery__button {
    max-width: 100%;
    width: 100%;
    background: #00792B;
    border-radius: 10px;
    color: #fff;
    order: 1;
    margin-bottom: 30px;
  }
  .spaceGallery__title {
    order: 2;
    font-size: 25px;
    line-height: 120%;
  }
  .spaceGallery__gallery {
    order: 3;
    margin-top: 20px;
  }
  .spaceGalleryShow__arrow {
    height: 40px;
    width: 40px;
  }
}