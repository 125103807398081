.filters {
  margin-bottom: 30px;
}
.filters__container {
  display: flex;
}
.filters__search {
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 20px;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
  width: 266px;
  height: 36px;
  margin-right: 10px;
}
.filters__searchLbl {
  height: 100%;
  width: 100%;
  display: block;
}
.filters__searchInput {
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: block;
  padding: 9px 41px 9px 20px;
}
.filters__searchBtn {
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  font-size: 0;
  width: 41px;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6732 12.6126C13.3804 12.3197 12.9055 12.3197 12.6126 12.6126C12.3197 12.9055 12.3197 13.3804 12.6126 13.6733L13.6732 12.6126ZM15.4696 16.5304C15.7625 16.8233 16.2374 16.8233 16.5303 16.5304C16.8232 16.2375 16.8232 15.7626 16.5303 15.4697L15.4696 16.5304ZM12.6126 13.6733L15.4696 16.5304L16.5303 15.4697L13.6732 12.6126L12.6126 13.6733ZM7.42858 13.1072C4.29239 13.1072 1.75 10.5648 1.75 7.42858H0.25C0.25 11.3932 3.46396 14.6072 7.42858 14.6072V13.1072ZM13.1072 7.42858C13.1072 10.5648 10.5648 13.1072 7.42858 13.1072V14.6072C11.3932 14.6072 14.6072 11.3932 14.6072 7.42858H13.1072ZM7.42858 1.75C10.5648 1.75 13.1072 4.29239 13.1072 7.42858H14.6072C14.6072 3.46396 11.3932 0.25 7.42858 0.25V1.75ZM7.42858 0.25C3.46396 0.25 0.25 3.46396 0.25 7.42858H1.75C1.75 4.29239 4.29239 1.75 7.42858 1.75V0.25Z' fill='%23A9A9A9'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center left 10px;
  background-color: transparent;
}
.filters__sort {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
}
.filters__sortLbl,
.filters__sortLink {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #A9A9A9;
  margin-right: 10px;
  text-decoration: none;
  &:hover {
    color: #A9A9A9;
  }
  &_active {
    color: #262626;
    text-decoration: underline;
  }
}
.filters__category {
  margin-right: 20px;
}

@include media-breakpoint-down(md) {
  .filters {
    display: none;
  }
}