.productBlocks__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -12px;
  margin-right: -12px;
}
.productsBlocks__item {
  width: calc(100% / 3 - 24px);
  margin-bottom: 24px;
  margin-right: 12px;
  margin-left: 12px;
  height: 188px;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.5);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &_honey {
    background-image: url(../img/products/1.jpg);
  }
  &_milk {
    background-image: url(../img/products/2.jpg);
  }
  &_fruits {
    background-image: url(../img/products/3.jpg);
  }
  &_meat {
    background-image: url(../img/products/4.jpg);
  }
  &_gastronome {
    background-image: url(../img/products/5.jpg);
  }
  &_vegetables {
    background-image: url(../img/products/6.jpg);
  }
  &_fish {
    background-image: url(../img/products/7.jpg);
  }
  &:last-child {
    height: 123px;
    width: 100%;
    margin-bottom: 0;
  }
}
.productsBlocks__label {
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
  text-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
}

@include media-breakpoint-only(md) {
  .productsBlocks__item {
    width: calc(50% - 24px);
  }
}

@include media-breakpoint-down(md) {
  .productBlocks__list {
    margin-left: 0;
    margin-right: 0;
  }
  .productsBlocks__item {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: 167px;
    &:last-child {
      height: 167px;
    }
  }
  .productsBlocks__label {
    font-size: 40px;
    line-height: 120%;
    text-shadow: none;
  }
}