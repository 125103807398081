.map {
  margin-bottom: 100px;
}
.map__title {
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  color: #262626;
  margin-bottom: 30px;
}
.map__area {
  height: 397px;
  overflow: hidden;
}

@include media-breakpoint-down(md) {
  .map {
    margin-bottom: 40px;
  }
  .map__title {
    font-size: 30px;
    line-height: 130%;
    margin-bottom: 10px;
  }
  .map__area {
    border-radius: 15px;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
  }
}