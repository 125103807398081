.contact__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.contact__info {
  width: calc(50% - 12px);
  background-image: url("data:image/svg+xml,%3Csvg width='558' height='222' viewBox='0 0 558 222' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_8:63' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='558' height='222'%3E%3Crect width='558' height='222' rx='10' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_8:63)'%3E%3Ccircle cx='437' cy='260' r='87' fill='%23049340' fill-opacity='0.5'/%3E%3Ccircle cx='571' cy='260' r='158' fill='%23049340'/%3E%3C/g%3E%3C/svg%3E%0A");
  height: 222px;
  background-color: #fff;
  background-position: right bottom;
  background-repeat: no-repeat;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px 30px;
}

.contact__title {
  display: block;
  font-weight: 500;
  font-size: 50px;
  line-height: 69px;
  color: #262626;
}

.contact__subtitle {
  display: block;
  font-size: 34.67px;
  font-weight: 400;
  line-height: 34.67px;
  color: #262626;
}

.contact__form {
  width: calc(50% - 12px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact__formMessage {
  height: 166px;
  width: 100%;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}

.contact__formTextarea {
  width: 100%;
  border: none;
  resize: none;
  padding: 9px 20px;
  border-radius: 15px;
  overflow: hidden;
}

.contact__formInput {
  border: none;
  height: 36px;
  padding: 9px 20px;
  width: 100%;
  border-radius: 5px;
}

.contact__formColumn {
  width: calc((100% - 48px) / 3)
}

.contact__formBtn {
  width: calc((100% - 48px) / 3);
  background: #008538;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  height: 36px;
  padding: 9px;
  line-height: 18px;
  color: #FFFFFF;
  border: none;
  font-weight: normal;
}

@include media-breakpoint-down(md) {
  .contact__container {
    display: block;
  }
  .contact__info {
    width: 100%;
    background: none;
    height: auto;
    padding: 0;
    margin-bottom: 11px;
  }
  .contact__title {
    font-size: 30px;
    line-height: 130%;
    color: #262626;
    margin-bottom: 1px;
  }
  .contact__subtitle {
    font-size: 20px;
    line-height: 130%;
  }
  .contact__form {
    width: 100%;
    justify-content: space-between;
  }
  .contact__formInput,
  .contact__formTextarea {
    background: #DDDDDD;
    overflow: hidden;
  }
  .contact__formTextarea {
    border-radius: 10px;
  }
  .contact__formColumn {
    order: 1;
    width: calc(50% - 8px);
    margin-bottom: 10px;

  }
  .contact__formMessage {
    order: 2;
    height: 130px;
    margin-bottom: 10px;
    border-radius: 10px;

  }
  .contact__formBtn {
    order: 3;
    width: 100%;
    border-radius: 10px;
  }
}