.crocodileArticle {
  column-count: 2;
  column-gap: 24px;
  column-width: auto;
  max-width: 1140px;
}

.crocodileArticle__separator {
  break-after: column;
  background-color: transparent;
}

.crocodileArticle__pic {
  display: block;
  width: 100%;
  margin-bottom: 30px;

  &_mt {
    margin-top: 45px;
  }
}

.crocodileArticle__img {
  max-width: 100%;
}

.crocodileArticle__subtitle {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #262626;
  margin-bottom: 10px;
}

.crocodileArticle p {
  margin-bottom: 30px;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #262626;
}

.crocodileArticle__link {
  display: inline-block;
  border: 1px solid #00792B;
  border-radius: 20px;
  max-width: 169px;
  width: 100%;
  padding: 0;
  height: 36px;
  line-height: 36px;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  color: #00792B;
  &:hover, &:active {
    color: #fff;
    background-color: #00792B;
  }
}

@include media-breakpoint-down(lg) {
  .crocodileArticle {
    column-count: 1;
  }
  .crocodileArticle__separator {
    break-after: auto;
    display: none;
  }
  .crocodileArticle__pic {
    text-align: center;

    &_mt {
      display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .crocodileArticle__subtitle {
    font-size: 15px;
    line-height: 120%;
  }
  .crocodileArticle p {
    font-size: 12px;
    line-height: 130%;
    margin-bottom: 20px;
  }
  .crocodileArticle__pic {
    margin-bottom: 20px;
  }
  .crocodileArticle__link {
    max-width: 100%;
    background: #00792B;
    border-radius: 10px;
    color: #fff;
  }
}