.pageTitle {
  margin-bottom: 24px;
}
.pageTitle__container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.pageTitle__name {
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  color: #262626;
  margin-bottom: 5px;
}
.pageTitle__backLink {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #A9A9A9;
  text-decoration: none;
  padding-left: 32.75px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.67204 6.46731C9.96624 6.17572 10.4411 6.17784 10.7327 6.47204C11.0243 6.76624 11.0222 7.24111 10.728 7.53269L9.31758 8.93054C8.74268 9.50033 8.35733 9.88386 8.09823 10.2065C8.08635 10.2213 8.07487 10.2358 8.06379 10.25L15 10.25C15.4142 10.25 15.75 10.5858 15.75 11C15.75 11.4142 15.4142 11.75 15 11.75L8.06379 11.75C8.07487 11.7642 8.08635 11.7787 8.09823 11.7935C8.35733 12.1161 8.74268 12.4997 9.31758 13.0695L10.728 14.4673C11.0222 14.7589 11.0243 15.2338 10.7327 15.528C10.4411 15.8222 9.96624 15.8243 9.67204 15.5327L8.23089 14.1043C7.69472 13.573 7.24753 13.1298 6.92869 12.7328C6.59426 12.3163 6.33989 11.8787 6.27193 11.3455C6.25731 11.2308 6.25 11.1154 6.25 11C6.25 10.8846 6.25731 10.7692 6.27193 10.6545C6.33989 10.1213 6.59426 9.68367 6.92869 9.26724C7.24753 8.87023 7.6947 8.42705 8.23087 7.89568L9.67204 6.46731Z' fill='%23A9A9A9'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11C0.25 5.06294 5.06294 0.25 11 0.25ZM20.25 11C20.25 5.89137 16.1086 1.75 11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11Z' fill='%23A9A9A9'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  margin-left: auto;
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.67204 6.46731C9.96624 6.17572 10.4411 6.17784 10.7327 6.47204C11.0243 6.76624 11.0222 7.24111 10.728 7.53269L9.31758 8.93054C8.74268 9.50033 8.35733 9.88386 8.09823 10.2065C8.08635 10.2213 8.07487 10.2358 8.06379 10.25L15 10.25C15.4142 10.25 15.75 10.5858 15.75 11C15.75 11.4142 15.4142 11.75 15 11.75L8.06379 11.75C8.07487 11.7642 8.08635 11.7787 8.09823 11.7935C8.35733 12.1161 8.74268 12.4997 9.31758 13.0695L10.728 14.4673C11.0222 14.7589 11.0243 15.2338 10.7327 15.528C10.4411 15.8222 9.96624 15.8243 9.67204 15.5327L8.23089 14.1043C7.69472 13.573 7.24753 13.1298 6.92869 12.7328C6.59426 12.3163 6.33989 11.8787 6.27193 11.3455C6.25731 11.2308 6.25 11.1154 6.25 11C6.25 10.8846 6.25731 10.7692 6.27193 10.6545C6.33989 10.1213 6.59426 9.68367 6.92869 9.26724C7.24753 8.87023 7.6947 8.42705 8.23087 7.89568L9.67204 6.46731Z' fill='%2300792B'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 0.25C16.9371 0.25 21.75 5.06294 21.75 11C21.75 16.9371 16.9371 21.75 11 21.75C5.06294 21.75 0.25 16.9371 0.25 11C0.25 5.06294 5.06294 0.25 11 0.25ZM20.25 11C20.25 5.89137 16.1086 1.75 11 1.75C5.89137 1.75 1.75 5.89137 1.75 11C1.75 16.1086 5.89137 20.25 11 20.25C16.1086 20.25 20.25 16.1086 20.25 11Z' fill='%2300792B'/%3E%3C/svg%3E%0A");
    color: #00792B;
  }
}
.pageTitle__description {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  color: #A9A9A9;
  width: 100%;
}

@include media-breakpoint-down(md) {
  .pageTitle {
    padding-top: 30px;
    margin-bottom: 20px;
  }
  .pageTitle__name {
    font-size: 30px;
    text-align: center;
    line-height: 120%;
    width: 100%;
    margin-bottom: 0;
  }
  .pageTitle__backLink {
    display: none;
  }
  .pageTitle__description {
    display: none;
  }
}