.carousel {
  width: 100%;
  margin-bottom: 50px;
}

.carousel__carousel {
  position: relative;
}

.carousel-inner {
  overflow: hidden;
  position: relative;
}

/*.carousel-item {
  height: 460px;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: translateX(100%);
  transition: transform 1s;
}
.carousel-item.active {
  transform: translateX(0);
  transition: transform 1s;
}*/

.carousel-item {
  display: none;
}
.carousel-item.active {
  display: block;
}

.carousel-item img {
  width: 100%;
  object-fit: cover;
}

.carousel-control-prev,
.carousel-control-next {
  height: 60px;
  width: 60px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='22' viewBox='0 0 12 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L1.70711 10.2929C1.31658 10.6834 1.31658 11.3166 1.70711 11.7071L11 21' stroke='%2300792B' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  font-size: 0;

}

.carousel-control-prev {
  left: -30px;
}

.carousel-control-next {
  right: -30px;
  transform: rotate(180deg);
}

.carousel-indicators {
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.carousel-indicators > button {
  background: #fff;
  height: 10px;
  width: 10px;
  border: none;
  border-radius: 10px;
  margin-right: 2.5px;
  margin-left: 2.5px;
  padding: 0;
}

@include media-breakpoint-down(md) {
  .carousel {
    margin-bottom: 20px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .carousel__carousel {
    margin-left: -36px;
    margin-right: -36px;
  }
}