.crocodile {
  height: 300px;
  margin-bottom: 48px;
}
.crocodile__area {
  display: flex;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  background-image: url(/img/crocodile/crocodile.jpg);
  background-size: cover;
  background-position: center;
  &:before {
    position: absolute;
    display: block;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}
.crocodile__title {
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  color: #FFFFFF;
  text-align: center;
  position: relative;
  z-index: 3;
  margin-bottom: 10px;
}
.crocodile__text {
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  z-index: 3;
}
.crocodile__btn {
  height: 36px;
  line-height: 36px;
  display: block;
  position: relative;
  z-index: 3;
  color: #FFFFFF;
  background: #00792B;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 0 30px;
  text-decoration: none;
  font-size: 15px;
  margin-top: auto;
  &:hover, &:focus {
    color: #fff;
  }
}

@include media-breakpoint-down(md) {
  .crocodile__area {
    background-image: url(/img/crocodile/crocodile-sm.jpg);
    background-size: cover;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 20px;
    position: relative;
  }
  .crocodile__title {
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 5px;
    text-align: left;
    margin-top: auto;
  }
  .crocodile__text {
    font-size: 12px;
    line-height: 130%;
    text-align: left;
    margin-bottom: 0;
  }
  .crocodile__btn {
    position: absolute;
    bottom: 30px;
    right: 20px;
    height: 24px;
    width: 24px;
    font-size: 0;
    background-color: transparent;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='16' viewBox='0 0 26 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM25.7071 8.7071C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928931C18.9526 0.538406 18.3195 0.538406 17.9289 0.928931C17.5384 1.31946 17.5384 1.95262 17.9289 2.34314L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.7071ZM1 9L25 9L25 7L1 7L1 9Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}