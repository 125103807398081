.newsShowLayout {
  display: flex;
  position: relative;
}

.newsShowMain {
  background: #FFFFFF;
  padding: 30px 20px;
  border-radius: 15px;
  align-self: start;
  max-width: calc(50% - 12px);
  margin-bottom: 24px;
}

.newsShowMain p {
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  color: #262626;
  margin-bottom: 20px;
}

.newsShowAside {
  background: #FFFFFF;
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  position: absolute;
  max-width: calc(50% - 12px);
  right: 0;
  top: 0;
  bottom: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 755px;
}

.newsShowAside__title {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #262626;
  margin-bottom: 20px;
}

.newsShowAside__listWrap{
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #DDDDDD;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: content-box;
    background-color: #00792B;
    border-radius: 15px;
  }
}
.newsShowAside__list {
  overflow: hidden;
}

.newsShowAside__item {
  border-bottom: 1px solid #DDDDDD;
  padding: 20px 0;
  height: 200px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  &:last-child {
    border-bottom: none;
  }
}
.newsShowAside__imgLink {
  display: flex;
  margin-right: 26px;
  flex-grow: 0;
  flex-shrink: 1;
  height: 100%;
  max-width: 200px;
  width: 100%;
}
.newsShowAside__img {
  width: 100%;
  object-fit: contain;
  object-position: top left;
}


.newsShowAside__name,
.newsShowAside__name a {
  padding-right: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #262626;
  text-decoration: none;
}

.newsShowAside__text,
.newsShowAside__text a {
  padding-right: 5px;
  font-weight: 300;
  font-size: 15px;
  line-height: 19px;
  color: #A9A9A9;
  text-decoration: none;
}

.newsShowAside__date,
.newsShowAside__date a {
  padding-right: 5px;
  margin-top: auto;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: rgba(169, 169, 169, 0.5);
  text-decoration: none;
}

@include media-breakpoint-down(lg) {
  .newsShowMain {
    max-width: calc(60% - 12px);
  }
  .newsShowAside {
    max-width: calc(40% - 12px);
  }
  .newsShowAside__item {
    display: block;
    height: auto;
  }
  .newsShowAside__imgLink {
    margin-bottom: 12px;
  }
}

@include media-breakpoint-down(md) {
  .newsShowMain {
    max-width: 100%;
    background-color: transparent;
    padding: 0;
  }
  .newsShowMain p {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px
  }
  .newsShowAside {
    display: none;
  }}