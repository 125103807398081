.marketBlocks__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.marketBlocks__block {
  width: calc(50% - 12px);
  height: 196px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 24px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &:before{
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    content: '';
  }
  &:nth-child(1) {
    background-image: url(../img/market/1.jpg);
  }
  &:nth-child(2) {
    background-image: url(../img/market/2.jpg);
  }
  &:nth-child(3) {
    background-image: url(../img/market/3.jpg);
  }
  &:nth-child(4) {
    background-image: url(../img/market/4.jpg);
  }
}
.marketBlocks__title {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 50px;
  line-height: 59px;
  position: relative;
  z-index: 1;
}
.marketBlocks__link {
  margin-top: auto;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  height: 47px;
  background: #00792B;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  border-radius: 205px;
  display: inline-block;
  padding: 12px 18px;
  color: #FFFFFF;
  text-decoration: none;
  width: 157px;
  text-align: center;
  position: relative;
  z-index: 1;
  &:hover {
    color: #fff;
  }
}
@include media-breakpoint-between(md, xl) {
  .marketBlocks__title {
    font-size: 40px;
    line-height: 45px;
  }
}
@include media-breakpoint-down(md) {
  .marketBlocks__title {
    font-size: 4vw;
    line-height: 120%;
  }
  .marketBlocks__link {
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='16' viewBox='0 0 26 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7C0.447715 7 -4.82823e-08 7.44772 0 8C4.82823e-08 8.55228 0.447715 9 1 9L1 7ZM25.7071 8.7071C26.0976 8.31658 26.0976 7.68342 25.7071 7.29289L19.3431 0.928931C18.9526 0.538406 18.3195 0.538406 17.9289 0.928931C17.5384 1.31946 17.5384 1.95262 17.9289 2.34314L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.7071ZM1 9L25 9L25 7L1 7L1 9Z' fill='white'/%3E%3C/svg%3E%0A");
    background-color: transparent;
    background-repeat: no-repeat;
    font-size: 0;
    margin-top: auto;
    margin-left: auto;
    height: 24px;
    width: 24px;
  }
}