.contactMap {
  margin-bottom: 50px;
}

.contactMap__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contactMap__title {
  display: none;
}

.contactMap__map {
  height: 343px;
  overflow: hidden;
  width: calc(50% - 12px);
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.15));
  border-radius: 15px;
}

.contactMap__img {
  width: calc(50% - 12px);
  overflow: hidden;
  filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.15));
  border-radius: 15px;
}

.contactMap__img img {
  object-fit: cover;
  width: 100%;
  height: 343px;
}

@include media-breakpoint-down(md) {
  .contactMap__title {
    width: 100%;
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 130%;
    color: #262626;
    margin-bottom: 10px;
  }
  .contactMap__map {
    width: 100%;
  }
  .contactMap__img {
    display: none;
  }
}