.menu {
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.menu__container {
  display: flex;
  align-items: center;
}

.menu__search {
  max-width: 384px;
  height: 36px;
  background: #FAFAFC;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  width: 100%;
  flex-shrink: 1;

  &:hover {
    border: 1px solid #A9A9A9;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
  }
}

.menu__search-input {
  width: 100%;
  height: 100%;
  padding: 9px 35px 9px 15px;
  border: none;

  &:focus {
    outline: none;
  }
}

.menu__search-btn {
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6732 12.6126C13.3804 12.3197 12.9055 12.3197 12.6126 12.6126C12.3197 12.9055 12.3197 13.3804 12.6126 13.6733L13.6732 12.6126ZM15.4696 16.5304C15.7625 16.8233 16.2374 16.8233 16.5303 16.5304C16.8232 16.2375 16.8232 15.7626 16.5303 15.4697L15.4696 16.5304ZM12.6126 13.6733L15.4696 16.5304L16.5303 15.4697L13.6732 12.6126L12.6126 13.6733ZM7.42858 13.1072C4.29239 13.1072 1.75 10.5648 1.75 7.42858H0.25C0.25 11.3932 3.46396 14.6072 7.42858 14.6072V13.1072ZM13.1072 7.42858C13.1072 10.5648 10.5648 13.1072 7.42858 13.1072V14.6072C11.3932 14.6072 14.6072 11.3932 14.6072 7.42858H13.1072ZM7.42858 1.75C10.5648 1.75 13.1072 4.29239 13.1072 7.42858H14.6072C14.6072 3.46396 11.3932 0.25 7.42858 0.25V1.75ZM7.42858 0.25C3.46396 0.25 0.25 3.46396 0.25 7.42858H1.75C1.75 4.29239 4.29239 1.75 7.42858 1.75V0.25Z' fill='%23E1E1E1'/%3E%3C/svg%3E%0A");
  height: 15px;
  width: 15px;
  font-size: 0;
  display: block;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  border: none;
  background-color: transparent;
}

.menu__list {
  display: flex;
  margin-left: auto;
}

.menu__item {
  margin-top: 10px;
  margin-right: 45px;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.menu__submenu {
  display: none;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  flex-direction: column;
  top: 100%;
  overflow: hidden;
  z-index: 1;
}

.menu__subLink {
  padding: 10px 20px;
  flex-shrink: 0;
  white-space: nowrap;
  color: #A9A9A9;
  text-decoration: none;
  font-size: 15px;

  &:hover {
    background: #00792B;
    color: #fff;
  }
}

.menu__itemExpand {
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L4.29289 3.79289C4.68342 4.18342 5.31658 4.18342 5.70711 3.79289L9 0.5' stroke='%23A9A9A9' stroke-linecap='round'/%3E%3C/svg%3E%0A");
  background-color: transparent;
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  margin-left: 7px;
}

.menu__link {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #A9A9A9;
  text-decoration: none;
  background-image: linear-gradient(#262626, #262626);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  transition: background-size .3s;
  padding-bottom: 4px;

  &:after {
    position: relative;
  }

  &:last-child {
    margin-right: 0;
  }
}

@include media-breakpoint-up(md) {
  .menu__item:hover,
  .menu__item:active {
    overflow: visible;

    .menu__link {
      background-size: 100% 2px;
      text-decoration: none;
      color: #262626;
    }

    .menu__submenu {
      display: flex;
    }

    .menu__itemExpand {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.5L4.29289 1.20711C4.68342 0.816582 5.31658 0.816583 5.70711 1.20711L9 4.5' stroke='%23262626' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
}
@include media-breakpoint-only(md) {
  .menu__search {
    display: none;
  }
}
@include media-breakpoint-down(md) {
  .menu {
    height: inherit;
    display: none;
    &_expanded {
      display: block;
      position: absolute;
      top: 0;
      margin-top: 268.61px;
      height: calc(100vh - 268.61px);
      width: 100%;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
  .menu__container {
    display: block;
    max-width: inherit;
    padding: 20px 0.75rem 0;
  }
  .menu__search {
    display: none;
  }
  .menu__list {
    width: calc(100% + 1.5rem);
    margin-right: -0.75rem;
    margin-left: -0.75rem;
    padding: 0 32px;
    display: block;
  }
  .menu__item {
    margin: 0;
    height: 38px;
    border-bottom: 1px solid #DDDDDD;
    flex-wrap: wrap;
    padding: 0;
    overflow: hidden;

    &:last-child {
      border-bottom: none;
    }
  }
  .menu__link {
    background: none;
    height: 100%;
    padding: 10px 0;
    width: calc(100% - 38px);
    color: #262626;
  }
  .menu__submenu {
    position: static;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .menu__itemExpand {
    height: 38px;
    width: 38px;
    margin: 0;
  }
  .menu__subLink {
    color: #A9A9A9;
    height: 38px;
    padding: 0;
    line-height: 38px;
    border-bottom: 1px solid #DDDDDD;

    &:hover {
      color: #A9A9A9;
      background-color: #fff;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  .menu__item:hover,
  .menu__item:active {
    .menu__link {
      color: #00792B;
    }
  }
  .menu__item.menu__item_expanded {
    height: auto;

    .menu__link {
      color: #00792B
    }

    .menu__submenu {
      display: flex;
    }

    .menu__itemExpand {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 5L5.70711 1.70711C5.31658 1.31658 4.68342 1.31658 4.29289 1.70711L1 5' stroke='%2300792B' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
}